import { AppointmentModel, TimeAsPartsOfDay } from 'service/api';
import { TFunction } from 'react-i18next';
import React from 'react';
import { getAppointmentsListBlockDisplay } from 'utils/helperFunctions';

export const generateTimeOptions = (
  times: TimeAsPartsOfDay,
  trans: TFunction<'common', undefined>,
  doctorId?: string,
) => {
  const morningTimes = times.morning.map(
    (t: TimeAsPartsOfDay['morning'][number]) => ({
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ display: 'inline-block' }}>
            {`${trans('morning')} ${t.time}`}
          </span>
          {doctorId
            ? getAppointmentsListBlockDisplay(
                (t.overlappings as AppointmentModel.type[]) || [],
              )
            : null}
        </div>
      ),
      value: t.time,
    }),
  );
  const afternoonTimes = times.afternoon.map(
    (t: TimeAsPartsOfDay['afternoon'][number]) => ({
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ display: 'inline-block' }}>
            {`${trans('afternoon')} ${t.time}`}
          </span>
          {doctorId
            ? getAppointmentsListBlockDisplay(
                (t.overlappings as AppointmentModel.type[]) || [],
              )
            : null}
        </div>
      ),
      value: t.time,
    }),
  );
  return [...morningTimes, ...afternoonTimes];
};
