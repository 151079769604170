import React from 'react';
import moment from 'moment';
import { AppointmentModel, GetDoctorAvailableDurationsRo } from 'service/api';
import { SURGERY_TREATMENT_ID } from './constants';
import styles from '../pages/Appointment/components/AppointmentModal/AppointmentModal.module.scss';
import { over } from 'lodash';

export class HelperFunctions {
  static isSurgery = (treatmentId: string) => {
    return treatmentId === SURGERY_TREATMENT_ID;
  };
}

export const getAppointmentsListBlockDisplay = (
  overlappings: AppointmentModel.type[],
) => {
  return (
    <>
      {overlappings.map((overlapping) => {
        const isInternal = overlapping === AppointmentModel.type.INTERNAL;
        const isBreak = overlapping === AppointmentModel.type.BREAK;
        const isNonCurative = overlapping === AppointmentModel.type.MEETING;
        const isExpiringBlocker =
          overlapping === AppointmentModel.type.BLOCKER_EXPIRING;

        let backgroundColor: string = styles.bgBlue;

        if (isInternal) {
          backgroundColor = styles.bgLightPeach;
        }

        if (isBreak) {
          backgroundColor = styles.bgRed;
        }

        if (isNonCurative) {
          backgroundColor = styles.bgPink;
        }

        if (isExpiringBlocker) {
          backgroundColor = styles.bgGrey;
        }

        return (
          <div
            className={backgroundColor}
            style={{
              display: 'inline-block',
              width: 12,
              height: 12,
              borderRadius: 2,
              marginLeft: 8,
              border: '2px solid #292E62',
              marginTop: 2,
            }}></div>
        );
      })}
    </>
  );
};

export default function durationsToDurationOptions(
  durationsData?: GetDoctorAvailableDurationsRo['durations'],
) {
  return (
    durationsData?.map(({ duration, overlappings }) => {
      return {
        value: duration / 60,
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ display: 'inline-block' }}>
              {moment().startOf('day').add(duration, 'minutes').format('H:mm')}{' '}
            </span>
            {getAppointmentsListBlockDisplay(
              overlappings as AppointmentModel.type[],
            )}
          </div>
        ),
      };
    }) || []
  );
}
